<template>
    <div>
      <recomendations>
        
      </recomendations>
    </div>
</template>


<script>
import Recomendations from "@/views/specialists/sub-modules/financial-approval/views/recomendations/views/components/RecomendationsTable.vue"

export default {
    components: {
        Recomendations
    }
    
}
</script>